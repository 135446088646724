var linkDownloadFileCordova;
var linkOpenInWindowCordova;

var reInitDatePickerDialog;

var checkPlatceDphVisible;
var checkPlatceDphCzDic;

var tippyInstances = [];
var initMenuTippy;
var initTippyMenuButton;
var initTippyMenuInstance;
var hierarchyDatepickerInstance;
var initGallery;

if(Array.prototype.equals2) {
    console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
};

Array.prototype.equals2 = function (array) {
    if (!array)
        return false;

    if (this.length !== array.length)
        return false;

    for (var i = 0, l=this.length; i < l; i++) {
        if (this[i] instanceof Array && array[i] instanceof Array) {
            if (!this[i].equals2(array[i]))
                return false;
        }
        else if (this[i] !== array[i]) {
            return false;
        }
    }
    return true;
};

Object.defineProperty(Array.prototype, "equals2", {enumerable: false});

(function($) {

    //force hide banner
    $(document).on('click','[data-force-hide-app-banner]',function(e) {
        e.preventDefault();

        $('.part_scroll_banner').fadeOut('fast');

        let url = $(this).attr('data-force-hide-app-banner');
        $.ajax({
            url: url
        }).done(function (data) {

        });
    });

    /*$(document).on('click','[data-click-ajax]', function() {

        let url = $(this).attr('data-click-ajax');
        naja.makeRequest('POST', url, {},{history: false});

    });*/

    $(document).on('change','.part_ui_radio input',function() {
        var name = $(this).attr('name');

        $('.part_ui_radio input[name="' + name + '"]').closest('.part_ui_radio').removeClass('mod--invalid');

    });

    function checkRegistrationType()
    {
        if(!$('input[name="contractType"]:checked').length) {

            $('input[name="contractType"]').closest('.part_ui_radio').addClass('mod--invalid');

            return false;
        }

        $('input[name="contractType"]').closest('.part_ui_radio').removeClass('mod--invalid');

        return true;
    };

    //platce dph
    $(document).on('change','[data-platce-dph] input',function() {

        if($(this).prop('checked') === true) {

            var value = $(this).val();

            if(value === 'Y') {
                $('[data-ic-dph]').css('visibility','visible');
            } else if(value === 'N') {
                $('[data-ic-dph]').css('visibility','hidden');
            }
        }
    });

    $(document).on('click','[data-toggle-button]',function(e) {

        if($(this).is('[data-toggle-nocheck]')) {

        } else {
            var passed = checkRegistrationType();
            if(!passed) {
                e.preventDefault();
                return;
            }
        }

        let toggleVar = $(this).data('toggle-button');

        if($(this).is('[data-toggle-single-item]')) {

            let targetElem = $('[data-toggle-target="' + toggleVar + '"]');

            if(targetElem.is(':visible')) {
                targetElem.slideUp();
            } else {
                targetElem.slideDown();
            }

            return;
        } else {
            $('[data-toggle-target]').hide();
        }

        $('[data-toggle-target="' + toggleVar + '"]').slideDown();

        if(toggleVar === 'copy') {
            $('[data-copy-link-success]').hide();
        }
    });

    checkPlatceDphVisible = function()
    {
        if($('[data-platce-dph]').length) {
            if($('[data-platce-dph] input[value="Y"]:checked').length) {
                $('[data-ic-dph]').css('visibility','visible');
            }
        }
    };
    checkPlatceDphVisible();

    checkPlatceDphCzDic = function()
    {
        if(!$('form#frm-cooperation').length) {
            return;
        }

        if($('form#frm-cooperation').attr('data-country') !== 'CZ') {
            return;
        }

        let dicInput = $('input[name="dic"]');

        if($('input[name="platceDph"][value="Y"]:checked').length) {
            dicInput.attr('required','required');
        } else {
            dicInput.removeAttr('required');
        }

    };
    checkPlatceDphCzDic();

    $(document).on('change','form#frm-cooperation[data-country="CZ"] input[name="platceDph"]',function() {
        let dicInput = $('input[name="dic"]');

        var value = $(this).val();

        if($(this).prop('checked') === true) {

            if(value === 'Y') {
                dicInput.attr('required','required');
                dicInput.closest('.part_ui_input').removeAttr('data-no-validation');
                dicInput.closest('.part_ui_input').attr('data-init-validation','');
                try {
                    dicInput.closest('.part_ui_input').validation({
                        initial: true
                    });
                } catch (err) {

                }
            } else if(value === 'N') {
                dicInput.removeAttr('required');
                dicInput.closest('.part_ui_input').attr('data-no-validation','');
                dicInput.closest('.part_ui_input').removeAttr('data-init-validation');
                try {
                    dicInput.closest('.part_ui_input').validation();
                } catch (err) {

                }
            }
        }
    });

    function checkPlatceDph2()
    {
        if(parseInt($('[data-platce-dph-select]').val()) === 1) {
            $('[data-ic-dph2]').css('visibility','visible');
        } else {
            $('[data-ic-dph2]').css('visibility','hidden');
        }
    };

    if($('[data-platce-dph-select]').length) {
        checkPlatceDph2();

        $(document).on('change','[data-platce-dph-select]',function() {
            checkPlatceDph2();
        });
    }

    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    $(document).on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    reInitDatePickerDialog = function() {

        if($(document).find(".lib--dialog [data-datepicker]").length) {
            $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker."+lang+".min.js").done(function () {
                let el = $(document).find(".lib--dialog [data-datepicker]");

                let now = new Date();
                if (now.getMonth() === 11) {
                    var currentMonth = new Date(now.getFullYear() + 1, 0, 1);
                } else {
                    var currentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
                }

                let dp = el.find("input[type=\"text\"]").datepicker({
                    minDate: currentMonth,
                    view: "months",
                    minView: "months",
                    language: lang,
                    classes: "datepicker--dialog",
                    dateFormat: "MM yy",
                    altField: el.find("input[type=\"hidden\"]"),
                    altFieldDateFormat: "yyyy/mm/dd",
                    autoClose: true,
                }).data("datepicker");

                dp.selectDate(new Date(el.find("input[type=\"hidden\"]").val()));
            });
        }

    };

    nl_lib_dialog.init(function(){
        $(document).find(".lib--dialog .part_ui_btn").nl_lib_ripple();
        body.removeClass("is--loading");

        sr.reveal(".lib--dialog [data-reveal]", {
            duration: 1000,
            easing: 'ease',
            scale: null,
            distance: 0,
            delay: 300,
            viewFactor : 0,
            reset: false,
            mobile: true,
            beforeReveal: function (domEl) {
                $(domEl).attr("data-reveal","revealed");
            },
            afterReveal: function (domEl) {
                $(domEl).removeAttr("style");
            }
        },0);

        let dz_elm = doc.find(".lib--dialog .dropzone");
        if(dz_elm.length) {
            $.getStyle(cdnjs.dropzone_css);
            $.getScript(cdnjs.dropzone).done(function () {
                dz_elm.dropzone({
                    acceptedFiles: "image/*"
                });
            });
        }

        if ($(document).find(".lib--dialog .part_dialog_event").length) {
            (function fn_dialog_event(selectorOld) {

                let selector = $(".lib--dialog .part_dialog_event");

                let checkin = selector.find('[data-form-datepicker="arrival"]');
                let checkout = selector.find('[data-form-datepicker="departure"]');
                let popup_from = selector.find('[data-form-datepicker="popupfrom"]');
                let popup_to = selector.find('[data-form-datepicker="popupto"]');

                if (checkin.length && checkout.length) {

                    $(".datepickers-container").remove();

                    let lang_url;

                    if (lang === "ru") {
                        lang_url = cdnjs.datepicker_lang;
                    } else {
                        lang_url = cdnjs.datepicker_lang.replace(".cs.", "." + lang + ".")
                    }
                    $.getScript(lang_url).done(function () {

                        $(checkin).each(function(){

                            let checkin = $(this);
                            let checkout = checkin.closest(selector).find('[data-form-datepicker="departure"]');

                            let datepicker_in = checkin.find("[data-arrival]").datepicker({
                                language: lang,
                                position: "bottom center",
                                startDate: $("[data-arrival]").val() ? new Date($("[data-arrival]").val()) : null,
                                autoClose: true,
                                dateFormat: 'yyyy-mm-dd',
                                onShow: function () {
                                    doc.find(".datepickers-container").addClass("state--active").children(".datepicker").attr("data-title", checkin.data("title"));
                                },
                                onHide: function () {
                                    doc.find(".datepickers-container").removeClass("state--active");
                                },
                                onSelect: function (fd, d) {
                                    let dateOut = new Date(d.getTime());
                                    dateOut.setDate(dateOut.getDate());
                                    checkout.find("[data-departure]").datepicker().data('datepicker').selectDate(dateOut);
                                }
                            }).data('datepicker');

                            checkin.on("click", function () {
                                datepicker_in.show();
                            });
                        });

                        $(checkout).each(function(){
                            let checkout = $(this);
                            let datepicker_out = checkout.find("[data-departure]").datepicker({
                                language: lang,
                                position: "bottom center",
                                startDate: $("[data-departure]").val() ? new Date($("[data-departure]").val()) : null,
                                autoClose: true,
                                dateFormat: 'yyyy-mm-dd',
                                onShow: function () {
                                    doc.find(".datepickers-container").addClass("state--active").children(".datepicker").attr("data-title", checkout.data("title"));
                                },
                                onHide: function () {
                                    doc.find(".datepickers-container").removeClass("state--active");
                                }
                            }).data('datepicker');

                            checkout.on("click", function () {
                                datepicker_out.show();
                            });
                        });

                        $(popup_from).each(function(){

                            let popup_from = $(this);
                            let popup_to = popup_from.closest(selector).find('[data-form-datepicker="popupto"]');

                            let datepicker_popup_from = popup_from.find("[data-popupfrom]").datepicker({
                                language: lang,
                                position: "bottom center",
                                startDate: $("[data-popupfrom]").val() ? new Date($("[data-popupfrom]").val()) : null,
                                autoClose: true,
                                dateFormat: 'yyyy-mm-dd',
                                onShow: function () {
                                    doc.find(".datepickers-container").addClass("state--active").children(".datepicker").attr("data-title", popup_from.data("title"));
                                },
                                onHide: function () {
                                    doc.find(".datepickers-container").removeClass("state--active");
                                },
                                onSelect: function (fd, d) {
                                    let dateOut = new Date(d.getTime());
                                    dateOut.setDate(dateOut.getDate());
                                    popup_to.find("[data-popupto]").datepicker().data('datepicker').selectDate(dateOut);
                                }
                            }).data('datepicker');

                            checkin.on("click", function () {
                                datepicker_popup_from.show();
                            });
                        });

                        $(popup_to).each(function(){
                            let popup_to = $(this);
                            let datepicker_popup_to = popup_to.find("[data-popupto]").datepicker({
                                language: lang,
                                position: "bottom center",
                                startDate: $("[data-popupto]").val() ? new Date($("[data-popupto]").val()) : null,
                                autoClose: true,
                                dateFormat: 'yyyy-mm-dd',
                                onShow: function () {
                                    doc.find(".datepickers-container").addClass("state--active").children(".datepicker").attr("data-title", popup_to.data("title"));
                                },
                                onHide: function () {
                                    doc.find(".datepickers-container").removeClass("state--active");
                                }
                            }).data('datepicker');

                            popup_to.on("click", function () {
                                datepicker_popup_to.show();
                            });
                        });

                    });
                }
            })($(".lib--dialog .part_dialog_event"));
        }

        if($(document).find(".lib--dialog [data-datepicker]").length) {
            $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker."+lang+".min.js").done(function () {
                let el = $(document).find(".lib--dialog [data-datepicker]");
                let dp = el.find("input[type=\"text\"]").datepicker({
                    minDate: new Date(),
                    view: "months",
                    minView: "months",
                    language: lang,
                    classes: "datepicker--dialog",
                    dateFormat: "MM yy",
                    altField: el.find("input[type=\"hidden\"]"),
                    altFieldDateFormat: "yyyy/mm/dd",
                    autoClose: true,
                }).data("datepicker");

                dp.selectDate(new Date(el.find("input[type=\"hidden\"]").val()));
            });
        }

        if($(document).find(".lib--dialog [data-countup]").length) {
            $.getScript(cdnjs.countup).done(function () {
                $(document).find(".lib--dialog [data-countup]").each(function () {
                    let el = $(this);
                    let parent = el.closest("[data-reveal]");
                    let interval;
                    let duration = 3;
                    let decimals = 0;
                    if(el.data('decimals')) {
                        decimals = parseInt(el.data('decimals'));
                    }

                    let options = {
                        separator: ' ',
                        decimal: '.',
                        suffix: (el.data('countup').suffix) ? " " + el.data('countup').suffix : "",
                    };

                    if(parseInt(el.data("countup").value) > 100000) {
                        duration = 1.5;
                    }

                    let counter = new CountUp(el.get(0),0,parseFloat(el.data("countup").value),decimals,duration,options);
                    if (!counter.error) {
                        counter.start();
                    } else {
                        console.error(counter.error);
                    }
                })
            });
        }

        if($(document).find(".lib--dialog [data-progress]").length) {
            $.getScript(cdnjs.progressbarjs).done(function () {
                let card_level = $(".lib--dialog .part_dashboard_level");

                let bar_wrap = card_level.find(".elm_body_bar");
                let bar = new ProgressBar.Circle(bar_wrap.get(0), {
                    strokeWidth: 8,
                    easing: 'easeInOut',
                    duration: 2500,
                    trailWidth: 8,
                    svgStyle: null
                });

                $(bar.svg).addClass("elm_progress");
                $(bar.trail).removeAttr("stroke").addClass("elm_trail");
                $(bar.path).removeAttr("stroke").addClass("elm_fill " + bar_wrap.data("progress").color);


                let interval;
                interval = setInterval(function () {
                    if (card_level.attr("data-reveal") === "revealed") {
                        clearInterval(interval);
                        bar.animate(bar_wrap.data("progress").progress);
                    }
                }, 150);
            });
        }

        if($(document).find(".lib--dialog .part_player_audio").length) {
            $(document).find(".lib--dialog .part_player_audio").each(function () {
                libPlayerAudio($(this)[0]);
            })
        }

        if($(document).find(".lib--dialog [data-lib-datepicker]").length) {
            $(document).find(".lib--dialog [data-lib-datepicker]").each(function () {
                libDatepicker($(this)[0]);
            })
        }
    });

    $(document).on('change','[data-check-number]',function() {
        let thisVal = parseInt($(this).val());

        let maxVal = parseInt($(this).attr('max'));

        if(maxVal && thisVal > maxVal) {
            $(this).val(maxVal);
        }

    });

    $(document).on('click','[data-toggle-video-like]',function() {
        $(this).find('span:first').toggleClass('icon--heart-fill icon--heart');

    });

    $(document).on('click', '[data-toggle-admin-menu]', function() {
        $(this).next().slideToggle()
    });

    $(document).on('click', '[data-dialog-pdf]', function (e) {
        e.preventDefault();
        $.ajax({
            url: $(this).data('dialog-pdf')
        }).done(function (data) {
            nl_lib_dialog.open(data.dialog, function(){
                $(".lib--dialog").addClass("mod--pdf");
                $("body").css("overflow","hidden");
                body.removeClass("is--loading");
            });
        });
    });

    $("[data-switch]").nl_lib_switch();
    $(".part_ui_btn").nl_lib_ripple();
    $(".part_ui_number button").nl_lib_ripple();

    if ($body.filter("[data-dialog-open]").length) {

        if (!$body.data('dialog-open').startsWith('#')) {
            if(location.hostname === "localhost") {
                $.ajax({
                    url: $body.data('dialog-open')
                }).done(function (data) {
                    nl_lib_dialog.open(data.dialog);
                });
            }
            else {
                naja.makeRequest('GET',$body.data('dialog-open'),{},{history: false}).then(function(data) {});
            }
        } else {
            nl_lib_dialog.open($($body.data('dialog-open'))[0].innerHTML);
        }
    }

    $('[type="tel"]').intlTelInput({
        nationalMode: false,
        preferredCountries: ['cz', 'sk', 'pl'],
        initialCountry: $html.attr("data-country"),
        customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
            return '+' + selectedCountryData.dialCode;
        }
    });

    $(document).on('click', 'button[type="submit"]', function (e) {
        var $form = $(this).closest('form');
        var $telInput = $form.find('[type="tel"]');

        if ($telInput.length > 0) {
            var isValid = $telInput.intlTelInput("isValidNumber");

            if (!isValid) {
                $telInput[0].setCustomValidity($telInput.data("validation-text"));
            }
        }
    });


    $(document).on("click", "[data-print]", function() {
        printJS($(this).data("print"));
    });

    $(document).on("click","[data-toggle-collapse]",function () {

        let ajaxUri = $(this).attr('data-ajax-link');
        let timeout;
        body.addClass("is--collapsing");
        clearTimeout(timeout);
        timeout = setTimeout(()=>{
            body.removeClass("is--collapsing");
        },300);
        if(body.hasClass("is--collapsed")) {
            body.removeClass("is--collapsed");
            naja.makeRequest('POST', ajaxUri, {'collapsed': 0},{history: false});

            for (var i = 0; i < tippyInstances.length; i++) {
                let tippy = tippyInstances[i];
                tippy.destroy();
            }

            if(typeof initTippyMenuButton !== 'undefined') {
                initTippyMenuButton();
            }

        } else {
            body.addClass("is--collapsed");
            naja.makeRequest('POST', ajaxUri, {'collapsed': 1},{history: false});

            if(typeof initMenuTippy !== 'undefined') {
                initMenuTippy();
            }
            if(typeof initTippyMenuButton !== 'undefined') {
                initTippyMenuButton();
            }
        }
    });

    // nl_lib_notification.open({
    //     state: "success",
    //     title: "Chyba",
    //     text: "Text notifikace",
    //     autohide: false
    // });

    var picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !$("html").hasClass("ie"))
    {
        $.getScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        $html.addClass("ie11");
    }
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
        $html.addClass("safari")
    }

    $('input,select,textarea').each(function(){
        $(this)[0].addEventListener('invalid', function(){
            this.scrollIntoView(false);
        });
    });

    $doc.on('submit','form.part_form:not(.ajax), form.prevent_multiple_send', function(e) {
        $(this).find('button').attr('disabled','disabled');
    });

    $doc.on('click','[data-disabled-multiple-click]',function(e) {
        if($(this).hasClass('clicked')) {
            e.preventDefault();
        } else {
            $(this).addClass('clicked');
        }
    });

    //packetery
    function setPacketeryBranchName()
    {
        var selectedText = $('.packetery-branch-list [name="pobocka"] option:selected').text();
        var selectedVal = $('.packetery-branch-list [name="pobocka"]').val();
        $('input[name="packeteryBranchName"]').val(selectedText);

        if($('.packetery-branch-list').length) {
            if(typeof $('.packetery-branch-list')[0].packetery !== 'undefined') {

                var pcktry = $('.packetery-branch-list')[0].packetery;
                var pcktrySelected = pcktry.option('selected-data');

                if(pcktrySelected) {
                    $('input[name="packeteryStreet"]').val(pcktrySelected.street);
                    $('input[name="packeteryCity"]').val(pcktrySelected.city);
                    $('input[name="packeteryZip"]').val(pcktrySelected.zip);
                    $('input[name="packeteryCountry"]').val(pcktrySelected.country);

                    if(typeof pcktrySelected.region !== 'undefined') {
                        var selectedRegion = pcktrySelected.region;

                        var regionSelect = $('.packetery-branch-list').find('select:not([name="pobocka"])');
                        regionSelect.val(selectedRegion);
                        regionSelect.trigger('change');
                        regionSelect.find('option[value="' + selectedRegion + '"]').trigger('click');

                    }
                }

            }
        }
    }

    $doc.on('change', '.packetery-branch-list [name="pobocka"]', function() {
        setPacketeryBranchName();
    });

    setTimeout(function() {
        setPacketeryBranchName();
    },1500);

    var $notification = $('[data-notification-global]');
    if ($notification.length > 0) {
        $notification.each(function() {
            nl_lib_notification.open({
                autohide: true,
                state: $(this).data('state'),
                title: $(this).data('title'),
                text: $(this).data('text')
            });
        });
    }

    linkDownloadFileCordova = function(elem) {

        var hrefOriginal = elem.attr('href');
        var file = hrefOriginal;
        file = file.split(/(\\|\/)/g).pop();
        var fileExt = file;
        fileExt = fileExt.split('.').pop();
        fileExt = fileExt.toLowerCase();



        if (window.Capacitor) {
            window.downloadFileCapacitor(hrefOriginal, file, undefined, false)
        } else {
            app.downloadFile(hrefOriginal, file, null, false, fileExt);
        }
    };

    linkOpenInWindowCordova = function(elem) {

        var link = elem.attr('href');

        if (window.Capacitor) {
            window.openFileInWindowCapacitor(link)
        } else {
            app.openFileInWindow(link);
        }

    };

    var currentToken = null;
    var currentFileType = null;
    var formSubmitProcessing = false;

    function downloadFileCordova(elem)
    {
        var mimeType = 'application/pdf';

        if(elem.data('mime-type')) {
            if(elem.data('mime-type').length) {
                mimeType = elem.data('mime-type');
            }
        }

        showLoadingDialog();

        if (window.Capacitor) {
            let href;
            
            if (elem.attr('data-file-url')) {
                href = elem.attr('data-file-url')
            } else {
                href = elem.attr('href')
            }

            window.downloadFileCapacitor(href, elem.data('name'), mimeType/*'application/pdf'*/, true)
        } else {
            app.downloadFile(elem.attr('href'), elem.data('name'), mimeType/*'application/pdf'*/, true);
        }
    }

    function showLoadingDialog()
    {
        var lDialog = $('.part_dialog.part_dialog_loading');
        var lShowDialog = lDialog.clone();
        $('body').append(lShowDialog);
        if (lShowDialog.length) {
            let delayElement = lShowDialog.find('.elm_later[data-delay]');
            if (delayElement) {
                let delay = delayElement.data('delay');
                let timeout = setTimeout(function () {
                    lShowDialog.find('.elm_loading').addClass('mod--hide')
                    lShowDialog.find('.elm_later').removeClass('mod--hide');
                }, delay)
                let sendButton = delayElement.find('button[data-send]')
                sendButton.on('click', function () {
                    checkIfDownloaded(true)
                })
            }
            lShowDialog.show();
            nl_lib_dialog.open(lShowDialog);
        }

        $('.elm_dialog_background').attr('data-dialog-sticky', '');
    }

    function checkIfDownloaded(sendToEmail = false)
    {
        $.ajax({
            url: '?do=checkFileDone&token=' + currentToken + '&type=' + currentFileType + '&sendToEmail=' + (sendToEmail ? '1' : '0'),
            context: document.body,
            cache: false,
            dataType: 'json',
            success: function (data) {
                if ((typeof data.done !== 'undefined' && data.done === true)) {
                    nl_lib_dialog.close();
                    formSubmitProcessing = false;
                    if (typeof data.download !== 'undefined') {
                        window.location.href = data.download;
                    }
                } else {
                    setTimeout(checkIfDownloaded, 1000)
                }
            },
            error: function () {
                setTimeout(checkIfDownloaded, 1000)
            }
        });

    }

    doc.on('click', '[data-download-file="global"]', function (e) {
        var elm = $(this);

        if ((typeof cordova !== 'undefined' && typeof cordovaDeviceReady !== 'undefined' && cordovaDeviceReady === true) || window.Capacitor) {
            e.preventDefault();
            downloadFileCordova(elm);
            return;
        }

        if (!window.Capacitor && elm.attr('data-file-url')) {
            e.preventDefault();
            naja.makeRequest('GET', elm.attr('href'), {}, {history: false}).then(function(data) {});
        }

        currentToken = elm.data('token');
        currentFileType = elm.data('type');

        if(currentToken) {
            setTimeout(checkIfDownloaded, 1000)
            if (!formSubmitProcessing) {
                showLoadingDialog();
                formSubmitProcessing = true;
            }
        }
    });


    doc.on("click", "[data-add-cart]", function(e){
        $("body").append('<div class="layout_summary_circle"></div>');

        let summary_circle =  $(".layout_summary_circle");
        let basket = $("#snippet--basketTop");
        let topOffset = "32px";
        if (basket.length <= 0) {
            basket = $("#snippet--webshopFooter")
            topOffset = "95%";
        }

        summary_circle.css({
            "top":  e.clientY + "px",
            "left":  e.clientX + "px"
        });

        summary_circle.animate({
            top: topOffset,
            left: basket.offset().left + (basket.width() / 2),
            width: 16,
            height: 16,
            opacity: 0.1,
        }, 800, function () {
            summary_circle.remove();
        });
    });


    doc.on("click", "a[href]", function(){
        if (!$(this).filter("[target]").length && !$(this).filter("[data-disable-body-loading]").length) {
             if ($(this).attr("href") !== "javascript:void(0)" && $(this).attr("href") !== "#" && $(this).attr("href") !== "" && !$(this).filter("[data-anchor]").length && !$(this).filter("[download]").length && !$(this).filter("[data-download]").length && !$(this).filter("[data-download-file]").length) {
                body.addClass("is--loading");
                if ($("#layout_nav").hasClass("ssm-nav-visible")) {
                    $(".ssm-nav-visible").removeClass("ssm-nav-visible");
                    $(".ssm-overlay").fadeOut(300);
                    $("htm").css("overflow","visible");
                }
            }
        }
    });

    doc.on('click','[data-print-article]',function(e) {

    });

    doc.on('click','a[data-action-loading]',function() {
        $(this).addClass('mod--loading')
    });

    doc.on('click','[data-add-cart]',function(e) {
        var form = $(this).closest('form');
        var numInput = form.find('input[type="number"]');
        setTimeout(function() {
            numInput.val(1).change();
        },300);
    });

    setInterval(function(){

        if($('section.comp_dashboard').length) {
            var reqUri = $('section.comp_dashboard').attr('data-dashboard-ajax-link');
            if(!reqUri) {
                return;
            }
            let filteredDate = null;
            if($('.elm_head_datepicker').find('input[name="hierarchy_date"]').length) {
                filteredDate = $('.elm_head_datepicker').find('input[name="hierarchy_date"]').val();
            }

            naja.makeRequest('POST', reqUri, {filteredDate: filteredDate},{history: false}).then(function(data) {

                if(typeof updateDashboardCountUps !== 'undefined' && updateDashboardCountUps) {
                    updateDashboardCountUps();
                }

                if(typeof updateDashboardProgressBars !== 'undefined' && updateDashboardProgressBars) {
                    updateDashboardProgressBars();
                }

                if(typeof updateDashboardDatePicker !== 'undefined' && updateDashboardDatePicker) {
                    updateDashboardDatePicker();
                }
            });
        }

    }, 60000);

    var morphToAnotherUserObj;
    //autocomplete find partner
    var morphToAnotherUser = $("[data-morh-to-another-user]");
    if(morphToAnotherUser.length) {

        morphToAnotherUserObj = morphToAnotherUser.easyAutocomplete({
            url: function(phrase) {
                return "?do=searchUserHierarchy";
            },
            getValue: function(element) {
                return element.name;
            },
            list: {
                match: {
                    enabled: true
                },
                onChooseEvent: function() {

                }
            },
            ajaxSettings: {
                dataType: "json",
                method: "POST",
                data: {
                    dataType: "json"
                }
            },
            preparePostData: function(data) {
                data.phrase = morphToAnotherUser.val();
                return data;
            },
            requestDelay: 400
        });
    }


    initMenuTippy = function() {
        tippyInstances = [];
        $('.tippy-menu-item').each(function() {
            let el = $(this);
            let tippyInstance = tippy(el[0],{
                placement: 'right',
                distance: -50
            });

            tippyInstances.push(el[0]._tippy);
        });
    };

    initTippyMenuButton = function() {

        if(initTippyMenuInstance) {
            initTippyMenuInstance.destroy();
        }

        let elmTippy = $('button[data-toggle-collapse]');
        if(body.is('.is--collapsed')) {

            elmTippy.attr('title',elmTippy.attr('data-title-expand'));

            let tippyObj = tippy(elmTippy[0],{
                placement: 'right',
                distance: -70
            });
            initTippyMenuInstance = elmTippy[0]._tippy;

        } else {

            elmTippy.attr('title',elmTippy.attr('data-title-collapse'));

            let tippyObj = tippy(elmTippy[0],{
                placement: 'right',
                distance: -150
            });
            initTippyMenuInstance = elmTippy[0]._tippy;

        }
    };

    if ($('button[data-toggle-collapse]').length) {
        initTippyMenuButton();
    }

    if (body.is('.is--collapsed')) {
        initMenuTippy();
    }

    $('[data-tippy-item]').each(function() {
        let el = $(this);
        let tippyInstance = tippy(el[0],{
            placement: 'right',
            distance: -50
        });
    });

    $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker."+lang+".min.js").done(function () {
        let now = new Date();

        var appCountry = $('html').attr('data-country');

        let minDate = moment('1.1.2017','DD.MM.YYYY');
        if(appCountry === 'cz' || appCountry === 'sk') {
            minDate = moment('1.1.2008','DD.MM.YYYY');
        } else if(appCountry === 'pl') {
            minDate = moment('1.1.2012','DD.MM.YYYY');
        }

        updateDashboardDatePicker = function() {

            let dp_elm = $('#layout_main').find(".elm_head_hierarchy_date");

            if(dp_elm.length) {

                if(typeof hierarchyDatepickerInstance !== 'undefined' && hierarchyDatepickerInstance) {
                    hierarchyDatepickerInstance.destroy();
                }

                hierarchyDatepickerInstance = dp_elm.find("input[type=\"text\"]").datepicker({
                    minDate: minDate.toDate(),
                    maxDate: now,
                    view: "months",
                    minView: "months",
                    language: lang,
                    dateFormat: "MM yyyy",
                    altField: dp_elm.find("input[type=\"hidden\"]"),
                    altFieldDateFormat: "yyyy/mm",
                    autoClose: true,
                    position: "bottom right",
                    onShow: function () {
                        $('.datepickers-container').addClass("state--active");
                        setTimeout(function() {
                            $('.datepickers-container .datepicker').addClass('active-after');
                        },1000);
                    },
                    onHide: function () {
                        $('.datepickers-container').removeClass("state--active");
                        $('.datepickers-container .datepicker').removeClass('active-after');
                    },
                    onSelect: function(formattedDate, date, inst) {
                        if(typeof doFilterDate !== 'undefined') {
                            doFilterDate();
                        }
                    }
                }).data("datepicker");
            }
        };
        updateDashboardDatePicker();
    });

    function doFilterDate(sortType)
    {
        let href = $('#layout_main').find('.elm_head_hierarchy_date').attr('data-ajax-link-date');

        if(!sortType) {
            sortType = '';
        }

        let filteredDate = $('.elm_head_hierarchy_date').find('input[name="hierarchy_date"]').val();

        let data = {
            filteredDate: filteredDate
        };

        //loading
        body.addClass("is--loading");
        if ($("#layout_nav").hasClass("ssm-nav-visible")) {
            $(".ssm-nav-visible").removeClass("ssm-nav-visible");
            $(".ssm-overlay").fadeOut(300);
            $("htm").css("overflow","visible");
        }

        naja.makeRequest('POST', href, data,{history: false}).then(function(response) {
            if (response.redirect) {
                window.location.href = response.redirect;
            } else {
                if (typeof updateDashboardDatePicker !== 'undefined' && updateDashboardDatePicker) {
                    updateDashboardDatePicker();
                }
                if (typeof reinitFooTable !== 'undefined' && reinitFooTable) {
                    reinitFooTable();
                }
            }
        });
    }

    $(document).on('change', '[data-select-ajax-change]', function (e) {
        var selectedValue = $(this).val();
        var ajaxUrl = $(this).attr('data-select-ajax-change');

        naja.makeRequest('POST', ajaxUrl, {value: selectedValue},{history: false})
    });

    $(document).on('click', '[data-copy-link-global]', function (e) {
        if (!$(this).is('[data-dont-prevent-default]')) {
            e.preventDefault();
        }

        if (navigator.clipboard) {
            navigator.clipboard.writeText($(this).data("copy-link-global")).catch(() => {
                if (typeof window.Capacitor !== 'undefined') {
                    window.writeToClipboardCapacitor($(this).data("copy-link-global"));
                }
            })
        } else {
            let textArea = document.createElement("textarea");
            textArea.value = $(this).data("copy-link-global");

            textArea.style.opacity = "0";
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }

        let successElem = $('[data-copy-link-success]');
        if(successElem.length) {
            successElem.slideDown('fast');
            setTimeout(function() {
                successElem.slideUp('fast');
            },3000);
        }
    });

    initGallery = function() {
        $("[data-lib-gallery]").each(function () {
            let gallery = $(this);

            libGallery(gallery);
        });
    }

    if($(document).find("[data-lib-gallery]").length) {
        initGallery();
    }

    if($(document).find("[data-lib-datepicker]").length) {
        $("[data-lib-datepicker]").each(function () {
            libDatepicker($(this)[0]);
        });
    }

    if($(document).find("[data-submit-dialog]").length) {
        $(document).find("[data-submit-dialog]").each(function () {
            const form = $(this);
            const submitButton = form.find('[type="submit"]')
            const dialogText = form.data('submit-dialog')
            const dialogTemplate =
                `<div class="part_dialog part_dialog_info part_dialog_loading" data-dialog-prevent-overlay-close>
                    <div class="wrp_content">
                        <h2 class="part_ui_heading"><i>${typeof dialogText.heading !== 'undefined' ? dialogText.heading : ''}</i></h2>
                        <div class="elm_loading">
                            <video muted autoplay loop>
                                <source src="/img/grass.webm" type="video/webm">
                            </video>
                            <div class="part_ui_wsw">
                                <p>${typeof dialogText.message !== 'undefined' ? dialogText.message : ''}</p>
                            </div>
                        </div>
                    </div>
                </div>`

            form.on('submit',function (e) {
                submitButton.prop('disabled',true)
                submitButton.addClass('mod--loading')
                nl_lib_dialog.open(dialogTemplate)
            })
        })
    }

    if($(document).find("[data-submit-disable]").length) {
        $(document).find("[data-submit-disable]").each(function () {
            const form = $(this);
            const submitButton = form.find('[type="submit"]')

            form.on('submit',function (e) {
                submitButton.prop('disabled',true)
                submitButton.addClass('mod--loading')
            })
        })
    }

    function showHideAnotherCountryShipping()
    {
        var anotherCountryCheckbox = $('input[name="anotherCountry"]:checked');
        if (anotherCountryCheckbox.length) {
            $('[data-another-country]').slideDown();
        } else {
            $('[data-another-country]').slideUp();
        }
    }
    $(document).on('change','input[name="anotherCountry"]', function() {
        showHideAnotherCountryShipping();
    });
    showHideAnotherCountryShipping();

})(jQuery);
